
import { Options, Vue } from 'vue-class-component';
import BaseNavigation from '@/components/BaseNavigation.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseInput from '@/components/BaseInput.vue';
import client from '@/client';

@Options({
  title: 'Discovery',
  components: { BaseNavigation, BaseButton, BaseInput },
  data() {
    return {
      discoveryOptions: [
        'Reddit',
        'Instagram',
        'Facebook',
        'Through a friend',
        'Blog or publication',
        'Employer',
        'Search engine (Google, Yahoo!, etc.)'
      ],
      radioOption: '',
      otherReason: '',
    }
  },
  methods: {
    submit() {
      let reason = '';
      if (this.radioOption === 'Other' && this.otherReason !== '') {
        reason = this.otherReason;
      } else {
        reason = this.radioOption;
      }
      client
      .submitDiscoveryAnswer(reason, this.$store.state.user.token)
      .then(() => {
        this.$router.push({ name: 'Dashboard', params: { navigatedFromCheckout: true } });
      });
    },
    skip() {
      this.$router.push({ name: 'Dashboard', params: { navigatedFromCheckout: true } });
    },
    shuffleArray(array: any) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      this.discoveryOptions = array;
    },
    handleEnter(event: any) {
      if (event.target) {
        const radio = event.target.querySelector('input[name=option]');
        if (radio) radio.click();
        if (event.target.className.includes('base-button')) event.target.click();
      }
    }
  },
  mounted() {
    this.shuffleArray(this.discoveryOptions);
  }
})

export default class Discovery extends Vue {}
