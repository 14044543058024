<template>
  <BaseNavigation>
    <div class="secure-server">
      <span>Secure server</span>
      <img :src="require('@/assets/lock.svg')" alt="Lock icon">
    </div>  
  </BaseNavigation>
  <div @keyup.enter.prevent="handleEnter($event)" class="discovery-view">
    <h1 class="title">How did you hear about us?</h1>
    <form @submit.prevent>
      <fieldset class="discovery-form__fields">
        <label
          v-for="(option, index) in discoveryOptions"
          :key="option + index"
          :for="option"
          class="discovery-view__option"
          :tabindex="index + 1">
          <input tabindex="-1" v-model="radioOption" name="option" type="radio" :id="option" :value="option">
          <p>{{ option }}</p>
        </label>
        <label tabindex="0" for="other" class="discovery-view__option option-other">
          <div>
            <input tabindex="-1" v-model="radioOption" name="option" type="radio" id="other" value="Other">
            <p>Other</p>
          </div>
          <BaseInput
            :model-value="otherReason"
            @update:model-value="otherReason = $event"
            v-if="radioOption === 'Other'"
            placeholder="Please explain"/>
        </label>
      </fieldset>
    </form>
    <div class="discovery-view__discovery-actions">
      <BaseButton tabindex="0" @click="skip()" class="secondary" text="Skip"/>
      <BaseButton tabindex="0" :disabled="radioOption === ''" @click="submit()" text="Continue"/>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BaseNavigation from '@/components/BaseNavigation.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseInput from '@/components/BaseInput.vue';
import client from '@/client';

@Options({
  title: 'Discovery',
  components: { BaseNavigation, BaseButton, BaseInput },
  data() {
    return {
      discoveryOptions: [
        'Reddit',
        'Instagram',
        'Facebook',
        'Through a friend',
        'Blog or publication',
        'Employer',
        'Search engine (Google, Yahoo!, etc.)'
      ],
      radioOption: '',
      otherReason: '',
    }
  },
  methods: {
    submit() {
      let reason = '';
      if (this.radioOption === 'Other' && this.otherReason !== '') {
        reason = this.otherReason;
      } else {
        reason = this.radioOption;
      }
      client
      .submitDiscoveryAnswer(reason, this.$store.state.user.token)
      .then(() => {
        this.$router.push({ name: 'Dashboard', params: { navigatedFromCheckout: true } });
      });
    },
    skip() {
      this.$router.push({ name: 'Dashboard', params: { navigatedFromCheckout: true } });
    },
    shuffleArray(array: any) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      this.discoveryOptions = array;
    },
    handleEnter(event: any) {
      if (event.target) {
        const radio = event.target.querySelector('input[name=option]');
        if (radio) radio.click();
        if (event.target.className.includes('base-button')) event.target.click();
      }
    }
  },
  mounted() {
    this.shuffleArray(this.discoveryOptions);
  }
})

export default class Discovery extends Vue {}
</script>

<style lang="scss">
@import '@/design';
@import '@/design/secureIcon';

.discovery-view {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  margin: auto;
  padding: 40px 10px;
  margin-bottom: 80px;
}
.discovery-view .title {
  margin-bottom: 32px;
}
.discovery-form__fields {
  border: unset;
  display: flex;
  flex-direction: column;
  padding: unset;
  margin: unset;
  flex-wrap: wrap;
  margin-bottom: 32px;
}
.discovery-view__option {
  display: flex;
  align-items: center;
  background: white;
  gap: 16px;
  border-bottom: 1px solid $gray-200;
  padding: 8px;
  cursor: pointer;
  outline: unset;
}
.discovery-view__option p {
  margin: 12px 0;
}
.discovery-view__option input[name=option] {
  height: 14px;
  width: 14px;
}
.discovery-view__option:hover,
.discovery-view__option:focus {
  background: $gray-100;
}
.discovery-view__option.option-other {
  align-items: unset;
  flex-direction: column;
  > div {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}
.discovery-view__option .base-input {
  flex-basis: unset;
  margin-bottom: 16px;
}
.discovery-view__option:first-of-type {
  border-radius: $base-radius;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.discovery-view__option:last-of-type {
  border-radius: $base-radius;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom: unset;
}
.discovery-view__discovery-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
</style>
